import {
  getTimezone,
  showToast,
  urlParams,
  wrappedPost,
} from "../general/utils.js";

const signOutToast = localStorage.getItem("signOutToast");

const signIn = (provider, email = null) => {
  wrappedPost({
    url: "/api/signin/create_url",
    data: JSON.stringify({
      timezone: getTimezone(),
      provider,
      email,
      noScope: urlParams.get("noScope"),
      redirect: urlParams.get("redirect"),
    }),
    success: (res) => {
      window.location = res.url;
    },
  });
};

const redirectParam = urlParams.get("redirect")
  ? `?redirect=${urlParams.get("redirect")}`
  : "";

export const initializeSignin = () => {
  if (signOutToast) {
    showToast(JSON.parse(signOutToast));
    localStorage.removeItem("signOutToast");
  }

  $("#signInMSButton").click(() => signIn("azure"));

  $("#signInGoogleButton").click(() => signIn("gcp"));

  $("#signInExchangeButton").click(() => {
    window.location = `/signin/exchange${redirectParam}`;
  });

  $("#signInSmtpButton").click(() => {
    window.location = `/signin/smtp${redirectParam}`;
  });

  $("#signInSamlButton").click(() => {
    const val = $("#emailInput").val();
    if (val.length) signIn("saml", val);
  });

  $("#samlBtn, #oauthBtn").click(() => {
    $("#samlDiv, #oauthDiv, #samlTextDiv, #oauthTextDiv").toggle();
  });
};
