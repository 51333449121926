import { getSessionKey } from "../../assets/js/src/general/websocket.js";
import { swRequest } from "./sw-request.js";

export const getUser = () =>
  swRequest({
    path: "/api/users/get",
    params: {
      sessionKey: getSessionKey(),
      href: window.location.href,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

export const getTodos = () =>
  swRequest({
    path: "/api/users/get_todos",
    method: "GET",
  });
