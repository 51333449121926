import { addWebsocketListener } from "../../assets/js/src/general/websocket.js";

const MetaStore = () => {
  let data = {
    fetching: false, // For spinners
    firstFetchDone: false, // For skeleton loading / once
  };

  const subscribers = new Set();
  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  const set = (newData) => {
    data = newData;
    subscribers.forEach((subscriber) => subscriber(data));
  };

  const update = (updater) => {
    data = updater(data);
    subscribers.forEach((subscriber) => subscriber(data));
  };

  return {
    subscribe,
    set,
    update,

    // utility methods to access the live data without subscribing. If
    // you're looking to set skeleton loading or spinners, you
    // probably want to subscribe to the store itself.
    fetching: () => data.fetching,
    firstFetchDone: () => data.firstFetchDone,
  };
};

export const SwApiStore = ({ get, onMessage, init, cacheKey }) => {
  const meta = MetaStore();

  const wrappedInit = () => {
    if (cacheKey) {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        return JSON.parse(cachedData);
      }
    }
    return init();
  };

  // Store content
  let data = wrappedInit();

  // Implementation of svelte store contract methods
  const subscribers = new Set();
  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  const notifySubscribers = () =>
    subscribers.forEach((subscriber) => subscriber(data));

  // Todo - consider adding meta as an optional second argument to the updater function?
  const update = (updater) => {
    data = updater(data);
    notifySubscribers();
  };

  const getAndUpdateStore = async () => {
    meta.update((value) => ({ ...value, fetching: true }));

    return get().then((newData) => {
      if (cacheKey) {
        localStorage.setItem(cacheKey, JSON.stringify(newData));
      }

      data = newData;
      notifySubscribers();
      meta.set({
        fetching: false,
        firstFetchDone: true,
      });
      return data;
    });
  };

  const once = async () => {
    if (meta.firstFetchDone()) return data;
    if (!meta.fetching()) return getAndUpdateStore();
    return new Promise((resolve) => {
      const unsubscribe = subscribe(() => {
        const resolveWhenReady = () => {
          if (meta.firstFetchDone()) {
            unsubscribe();
            resolve(data);
          } else {
            setTimeout(resolveWhenReady, 10);
          }
        };
        resolveWhenReady();
      });
    });
  };

  const reset = () => {
    meta.set({
      fetching: false,
      firstFetchDone: false,
    });
    data = init();
    notifySubscribers();
  };

  if (onMessage)
    addWebsocketListener((message) =>
      onMessage({ update, get: getAndUpdateStore, message }),
    );

  return {
    subscribe,
    get: getAndUpdateStore,
    once,
    meta: {
      // only surface subscribe method to make read-only.
      subscribe: meta.subscribe,
      fetching: meta.fetching,
      firstFetchDone: meta.firstFetchDone,
    },
    update,
    reset,
  };
};

export const SwDerivedApiStore = ({
  get,
  init,
  onMessage,
  argsStore,
  argsTransform = (x) => x,
  dataTransform = (x) => x,
}) => {
  let args;
  let data;
  const getWithArgs = () => get(...args);
  const apiStore = SwApiStore({
    get: getWithArgs,
    init,
    onMessage,
  });

  const getOrReset = () => {
    if (args) {
      apiStore.get();
    } else {
      apiStore.reset();
    }
  };

  argsStore.subscribe((res) => {
    const newArgs = argsTransform(res);
    if (newArgs && args && JSON.stringify(newArgs) === JSON.stringify(args))
      return;
    args = newArgs;
    getOrReset();
  });

  const subscribers = new Set();

  const subscribe = (subscriber) => {
    subscribers.add(subscriber);

    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  apiStore.subscribe((res) => {
    data = dataTransform(res);
    subscribers.forEach((subscriber) => subscriber(data));
  });

  const reset = () => {
    apiStore.reset();
    data = init();
    subscribers.forEach((subscriber) => subscriber(data));
  };

  return { subscribe, reset, meta: apiStore.meta, get: getOrReset };
};
